import React, { useEffect } from 'react'
import { Router } from '@reach/router'
import { navigate } from 'gatsby'

import { Root } from '../components/Root'
import { isLoggedIn } from '../services/auth'
import { Nav } from '../components/Nav'
import { Home } from '../components/Home'
import { AddIncome } from '../components/AddIncome'
import { AddExpense } from '../components/AddExpense'
import { Overview } from '../components/Overview'
import { ManageTags } from '../components/ManageTags'
import { SingleIncomeExpense } from '../components/SingleIncomeExpense'
import NotFound from './404'
import { setupFirebase, FirebaseContext } from '../components/Firebase'
import { Container } from '../system/components'

const App = () => {
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/login')
    }
  }, [])

  const firebase = setupFirebase()

  return isLoggedIn() ? (
    <Root>
      <Nav />
      <FirebaseContext.Provider value={firebase}>
        <Container pt={10}>
          <Router>
            <Home path="/app/home" />
            <AddIncome path="/app/add-income" />
            <AddExpense path="/app/add-expense" />
            <Overview path="/app/financial-overview" />
            <SingleIncomeExpense path="/app/financial-overview/:id" />
            <ManageTags path="/app/manage-tags" />
            <NotFound default />
          </Router>
        </Container>
      </FirebaseContext.Provider>
    </Root>
  ) : null
}

export default App
