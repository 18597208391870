import React from 'react'
import Downshift from 'downshift'

import { Input } from '../system/components'
import {
  StyledSuggesionItem,
  StyledSuggesionList,
  StyledInputWrapper,
  StyledClearButton,
} from './SuggestionList'
import { CrossIcon, ArrowIcon } from '../system/icons'

export const VendorInput = ({ vendors: items, vendor, dispatch }) => (
  <Downshift
    selectedItem={vendor || ''}
    onStateChange={selection => {
      if (selection.inputValue !== undefined) {
        dispatch({
          type: 'UPDATE_VENDOR',
          payload: selection.inputValue,
        })
      }
    }}
  >
    {({
      clearSelection,
      getInputProps,
      getItemProps,
      getLabelProps,
      getMenuProps,
      getToggleButtonProps,
      isOpen,
      inputValue,
      highlightedIndex,
      openMenu,
      selectedItem,
    }) => {
      const renderButton = () => {
        if (selectedItem) {
          return (
            <StyledClearButton
              data-testid="clear-vendors"
              onClick={clearSelection}
            >
              <CrossIcon />
            </StyledClearButton>
          )
        }

        return items.length ? (
          <StyledClearButton
            data-testid="show-vendors"
            {...getToggleButtonProps()}
          >
            <ArrowIcon isOpen={isOpen} />
          </StyledClearButton>
        ) : null
      }
      return (
        <div>
          <label {...getLabelProps()}>*Vendor</label>
          <StyledInputWrapper>
            <Input
              width={1}
              {...getInputProps({
                onFocus: openMenu,
              })}
            />
            {renderButton()}
          </StyledInputWrapper>
          <StyledSuggesionList {...getMenuProps()} isOpen={isOpen}>
            {isOpen
              ? items
                  .filter(
                    item =>
                      !inputValue ||
                      item.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => (
                    <StyledSuggesionItem
                      {...getItemProps({
                        key: item.label,
                        index,
                        item: item.label,
                        highlighted: highlightedIndex === index,
                      })}
                      data-testid="vendor-item"
                    >
                      {item.label}
                    </StyledSuggesionItem>
                  ))
              : null}
          </StyledSuggesionList>
        </div>
      )
    }}
  </Downshift>
)
