import React from 'react'
import { getMonth, getYear } from 'date-fns'

import { Select } from '../system/components/Select'

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getMonthYear = d => [getMonth(d), getYear(d)]
export const setInitialMonth = () => {
  const [monthNow, yearNow] = getMonthYear(Date.now())
  return `${MONTHS[monthNow]} ${yearNow}`
}

const createOptions = startOfTime => {
  const [monthThen, yearThen] = getMonthYear(startOfTime.toDate())
  const [monthNow, yearNow] = getMonthYear(Date.now())

  const yearsDiff = yearNow - yearThen
  const years = new Array(yearsDiff + 1)
    .fill(0)
    .map((_, index) => yearThen + index)

  const months = []
  years.forEach(year => {
    MONTHS.forEach((month, monthIndex) => {
      const fn = () => {
        if (year === yearThen && monthIndex < monthThen) return
        if (year === yearNow && monthIndex > monthNow) return
        months.push(`${month} ${year}`)
      }
      fn()
    })
  })

  return months
}

const MonthFilter = ({ startOfTime, selectedMonth, setSelectedMonth }) => {
  const handleChange = e => setSelectedMonth(e.currentTarget.value)
  const options = createOptions(startOfTime)
  return (
    <Select
      label="Select Month"
      value={selectedMonth}
      onChange={handleChange}
      options={options}
      width={1}
    />
  )
}

export { MonthFilter }
