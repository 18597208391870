import styled from 'styled-components'
import { spacing, flexbox } from '@material-ui/system'

const defaultGap = { xs: 2, md: 3, lg: 4 }

const neg = gap => {
  if (typeof gap === 'number') return -gap
  return Object.keys(gap).reduce((acc, curr) => {
    acc[curr] = -gap[curr]
    return acc
  }, {})
}

const getColGap = ({ gap, colGap }) => {
  let v = colGap || gap || 0
  if (v && typeof v === 'boolean') {
    v = defaultGap
  }
  return v
}

const getRowGap = ({ gap, rowGap }) => {
  let v = rowGap || gap || 0
  if (v && typeof v === 'boolean') {
    v = defaultGap
  }
  return v
}

const outerGap = props => {
  const colGap = getColGap(props)
  const rowGap = getRowGap(props)

  return spacing({
    theme: props.theme,
    ml: neg(colGap),
    mb: neg(rowGap),
  })
}

const innerGap = props => {
  const colGap = getColGap(props)
  const rowGap = getRowGap(props)

  return spacing({
    theme: props.theme,
    pl: colGap,
    pb: rowGap,
  })
}

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${spacing}
  ${flexbox}
  ${outerGap}
  > * {
    ${innerGap}
  }
`
