import { createGlobalStyle } from 'styled-components'

// const baseFontSize = 16
// const pxToRem = size => `${size / baseFontSize}rem`

export const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: antialiased;
    box-sizing: border-box;
  }
  *, *::before, *::after  {
    box-sizing: inherit;
  }
  body {
    background-color: white;
    color: #222;
    background: #fafafa;
    margin: 0;
    ${props => props.theme.typography.body1}
  }
  a {
    color: ${props => props.theme.palette.primary.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: 0;
  }
  h1 {
    ${props => props.theme.typography.h3}
    font-weight: 300;
  }
  h2 {
    ${props => props.theme.typography.h4}
    font-weight: 300;
  }
  h3 {
    ${props => props.theme.typography.h5}
    font-weight: 300;
  }
  h4 {
    ${props => props.theme.typography.h6}
    font-weight: 300;
  }
  h1, h2, h3, h4, h5, p {
    margin: 0 0 1rem 0;
  }
`
