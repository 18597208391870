import React from 'react'
import styled from 'styled-components'

import { Heading } from '../system/components'
import { convertToCurrency } from '../utils'

const StyledTotalList = styled.ul`
  margin-bottom: 24px;
`

const StyledTotalItem = styled.li`
  margin-bottom: 8px;
`

const StyledProfit = styled.span`
  color: ${({ profit }) => (profit < 0 ? 'red' : 'inherit')};
`

const getAllByType = (list, t) => list.filter(({ type }) => type === t)

const getAllIncomes = list => getAllByType(list, 'INCOME')
const getAllExpenses = list => getAllByType(list, 'EXPENSE')

const getTotalIncome = list =>
  getAllIncomes(list).reduce((acc, { amount }) => acc + amount, 0)

const getTotalExpenditure = list =>
  getAllExpenses(list).reduce((acc, { amount }) => acc + amount, 0)

export const Totals = ({ incomeExpenses, selectedMonth }) => {
  const totalIncome = getTotalIncome(incomeExpenses)
  const totalExpenditure = getTotalExpenditure(incomeExpenses)
  const profit = totalIncome - totalExpenditure
  return (
    <>
      <Heading fontSize="h5.fontSize">Totals for {selectedMonth}</Heading>
      <StyledTotalList>
        <StyledTotalItem>
          <strong>Income:</strong> {convertToCurrency(totalIncome)}
        </StyledTotalItem>
        <StyledTotalItem>
          <strong>Expenditure:</strong> {convertToCurrency(totalExpenditure)}
        </StyledTotalItem>
        <StyledTotalItem>
          <strong>Profit:</strong>{' '}
          <StyledProfit profit={profit}>
            {convertToCurrency(profit)}
          </StyledProfit>
        </StyledTotalItem>
      </StyledTotalList>
    </>
  )
}
