import React, { useState } from 'react'

import { Heading, Box, Checkbox, Button } from '../system/components'

const filterDuplicates = arr => {
  const seen = {}
  return arr.filter(item => {
    // eslint-disable-next-line
    if (seen.hasOwnProperty(item)) {
      return false
    }
    seen[item] = true
    return true
  })
}

export const Filters = ({ incomeExpenses, tags, onFilterChange, filters }) => {
  const [isOpen, setIsOpen] = useState(false)
  const visibleTags = filterDuplicates(
    incomeExpenses
      .map(({ tags: t }) => t)
      .reduce((acc, tag) => [...acc, ...tag], [])
  )
    .map(t => tags.find(tag => tag.id === t))
    .filter(Boolean)

  return (
    <Box mb={4}>
      <Heading fontSize="h5.fontSize" mb={4}>
        Filters{' '}
        <Button
          display="inline-block"
          small
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? '-' : '+'}
        </Button>
      </Heading>

      {isOpen && (
        <ul>
          {visibleTags.map(({ id, label }) => (
            <Box as="li" key={id} mb={1} mr={1} display="inline-block">
              <Checkbox
                id={id}
                onChange={onFilterChange}
                checked={!!filters[id]}
                label={label}
              />
            </Box>
          ))}
        </ul>
      )}
    </Box>
  )
}
