import styled, { css } from 'styled-components'
import { all } from '..'

export const Box = styled.div`
  ${all}
  ${({ center }) =>
    center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}
`
