import uuid from 'uuid/v4'
import { navigate } from 'gatsby'

import { getUser } from '../../services/auth'
import { COLORS } from '../../constants'

export const setIncomeExpense = (
  cb,
  firebase,
  {
    selectedTags,
    tags,
    vendors,
    vendor,
    description,
    amount,
    type,
    incomeExpenseId,
    action,
  }
) => {
  const db = firebase.firestore()
  const id = incomeExpenseId || `${Date.now()}`
  const batch = db.batch()
  const incomeExpensesRef = db.collection('income-expenses').doc(id)

  const enteredTags = Object.keys(selectedTags)
    .map(k => selectedTags[k])
    .filter(Boolean)
  const tagsAsStrings = tags.map(tag => tag.label.toLowerCase())
  const enteredTagsAsStrings = enteredTags.map(tag => tag.label.toLowerCase())

  const newTags = enteredTags.filter(
    tag => !tagsAsStrings.includes(tag.label.toLowerCase())
  )
  const exisitingTags = tags.filter(tag =>
    enteredTagsAsStrings.includes(tag.label.toLowerCase())
  )

  const tagsToSend = [...newTags, ...exisitingTags]

  const isNewVendor = !vendors.some(
    v => v.label.toLowerCase() === vendor.toLowerCase()
  )

  if (action === 'Add') {
    batch.set(incomeExpensesRef, {
      vendor,
      description,
      amount: parseInt(amount * 100, 10),
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      type,
      author: getUser().name === 'Dina' ? 1 : 0,
      tags: tagsToSend.map(t => t.id),
    })
  }

  if (action === 'Edit') {
    batch.update(incomeExpensesRef, {
      vendor,
      description,
      amount: parseInt(amount * 100, 10),
      tags: tagsToSend.map(t => t.id),
    })
  }

  newTags.forEach(({ label, id: tagId }) => {
    const tagsRef = db.collection('tags').doc(`${tagId}`)
    batch.set(tagsRef, {
      type,
      label,
      id: Number(tagId),
      hex: Object.keys(COLORS)[0],
    })
  })

  if (isNewVendor) {
    const newVendorId = uuid()
    const vendorsRef = db.collection('vendors').doc(newVendorId)
    batch.set(vendorsRef, {
      id,
      label: vendor,
    })
  }

  batch.commit().then(cb)
}

export const deleteIncomeExpense = (db, id) => {
  db.collection('income-expenses')
    .doc(id)
    .delete()
    .then(() => {
      setTimeout(() => {
        navigate('/app/home')
      }, 1000)
    })
}
