import React from 'react'
import styled from 'styled-components'
import { Link, navigate } from 'gatsby'

import { logout, getUser } from '../services/auth'
import { Container } from '../system/components'

const StyledNav = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  border-bottom: 2px solid #ededed;
`

const StyledNavLink = styled.a`
  display: inline-block;
  padding: 10px;
`

const StyledLoggedInAs = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  float: right;
`

export const Nav = () => {
  const username = getUser().name
  return (
    <StyledNav>
      <Container>
        <nav>
          <StyledNavLink as={Link} to="/app/home">
            Home
          </StyledNavLink>
          <StyledNavLink
            href="/"
            onClick={e => {
              e.preventDefault()
              logout({ onSuccess: () => navigate('/') })
            }}
          >
            Logout
          </StyledNavLink>
          {username && (
            <StyledLoggedInAs>Signed in as {username}</StyledLoggedInAs>
          )}
        </nav>
      </Container>
    </StyledNav>
  )
}
