import React, { useState, useEffect, useContext } from 'react'

import { FirebaseContext, getAllTags } from './Firebase'
import { Heading } from '../system/components'
import { TagToManage } from './TagToManage'

export const ManageTags = () => {
  const [tags, setTags] = useState([])
  const [editingTagId, setEditingTagId] = useState('')
  const firebase = useContext(FirebaseContext)
  const db = firebase.firestore()

  useEffect(() => {
    getAllTags(setTags, db)
  }, [])

  const handleEditClick = id => {
    setEditingTagId(id)
  }

  return (
    <>
      <Heading fontSize="h4.fontSize">Manage Tags</Heading>
      <ul>
        {tags.map(tag => (
          <TagToManage
            tag={tag}
            key={tag.id}
            isEditing={tag.id === editingTagId}
            onEditClick={handleEditClick}
          />
        ))}
      </ul>
    </>
  )
}
