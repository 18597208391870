import { addMonths, subMilliseconds } from 'date-fns'

export const getFirstIncomeExpenseTimestamp = db => {
  return new Promise(resolve => {
    const incomeExpensesRef = db.collection('income-expenses')
    incomeExpensesRef
      .limit(1)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { timestamp } = doc.data()
          resolve(timestamp)
        })
      })
  })
}

export const getAllIncomeExpenses = db => {
  return new Promise(resolve => {
    const list = []
    const incomeExpensesRef = db.collection('income-expenses')
    incomeExpensesRef.orderBy('timestamp', 'desc').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        list.push({
          id: doc.id,
          ...doc.data(),
        })
      })
      resolve(list)
    })
  })
}

export const getAllIncomeExpensesForGivenMonth = (db, monthStart) => {
  const monthEnd = subMilliseconds(addMonths(monthStart, 1), 1)
  return new Promise(resolve => {
    const list = []
    const incomeExpensesRef = db.collection('income-expenses')

    incomeExpensesRef
      .where('timestamp', '>', monthStart)
      .where('timestamp', '<', monthEnd)
      .orderBy('timestamp', 'desc')
      .onSnapshot(querySnapshot => {
        querySnapshot.forEach(doc => {
          list.push({
            id: doc.id,
            ...doc.data(),
          })
        })
        resolve(list)
      })
  })
}

export const getIncomeExpense = async (cb, db, id) => {
  await db
    .collection('income-expenses')
    .doc(id)
    .onSnapshot(doc => {
      cb(doc.data())
    })
}

export const getAllTags = async (cb, db) => {
  await db
    .collection('tags')
    .orderBy('label')
    .onSnapshot(docs => {
      const tags = []
      docs.forEach(doc => {
        tags.push({
          ...doc.data(),
        })
      })
      cb(tags)
    })
}

export const getAllVendors = async (cb, db) => {
  await db
    .collection('vendors')
    .orderBy('label')
    .onSnapshot(querySnapshot => {
      const vendors = []
      querySnapshot.forEach(doc => {
        vendors.push({ ...doc.data() })
      })
      cb(vendors)
    })
}
