import React from 'react'
import Downshift from 'downshift'

import { Input, Box } from '../system/components'
import { CrossIcon, ArrowIcon } from '../system/icons'
import {
  StyledSuggesionItem,
  StyledSuggesionList,
  StyledInputWrapper,
  StyledClearButton,
} from './SuggestionList'

export const TagSelector = ({
  tags: items,
  setSelectedTag,
  selectedTag = '',
  children,
}) => {
  const handleChange = changes => {
    // eslint-disable-next-line no-prototype-builtins
    if (changes.hasOwnProperty('inputValue')) {
      setSelectedTag(changes.inputValue)
    }
  }

  return (
    <div>
      <Downshift selectedItem={selectedTag} onStateChange={handleChange}>
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getToggleButtonProps,
          isOpen,
          inputValue,
          highlightedIndex,
          openMenu,
          selectedItem,
          clearSelection,
        }) => (
          <div>
            <Box>
              <label {...getLabelProps()}>{children}</label>
              <StyledInputWrapper>
                <Input
                  width={1}
                  {...getInputProps({
                    onFocus: openMenu,
                  })}
                />
                {selectedItem ? (
                  <StyledClearButton onClick={clearSelection}>
                    <CrossIcon />
                  </StyledClearButton>
                ) : items.length ? (
                  <StyledClearButton {...getToggleButtonProps()}>
                    <ArrowIcon isOpen={isOpen} />
                  </StyledClearButton>
                ) : null}
              </StyledInputWrapper>
              <StyledSuggesionList isOpen={isOpen} {...getMenuProps()}>
                {isOpen
                  ? items
                      .map(i => i.label)
                      .filter(
                        item =>
                          !inputValue ||
                          item.toLowerCase().includes(inputValue.toLowerCase())
                      )
                      .filter(item => {
                        return selectedItem.toLowerCase() !== item.toLowerCase()
                      })
                      .map((item, index) => (
                        <StyledSuggesionItem
                          {...getItemProps({
                            key: item,
                            index,
                            item,
                            highlighted: highlightedIndex === index,
                          })}
                        >
                          {item}
                        </StyledSuggesionItem>
                      ))
                  : null}
              </StyledSuggesionList>
            </Box>
          </div>
        )}
      </Downshift>
    </div>
  )
}
