import { useEffect } from 'react'
import { navigate } from 'gatsby'

const NotFound = () => {
  useEffect(() => {
    navigate('/login')
  }, [])

  return null
}
export default NotFound
