import styled from 'styled-components'

import { spacing } from '..'

const applyPadding = props => spacing({ theme: props.theme, px: 2 })

export const Container = styled.div`
  ${applyPadding}
  ${spacing}
  max-width: 900px;
  margin: 0 auto;
`
