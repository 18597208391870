import {
  borders,
  compose,
  css,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  style,
  typography,
} from '@material-ui/system'
import { theme } from './theme'

const all = compose(
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography
)

const sizingSpacing = compose(
  sizing,
  spacing
)

export {
  compose,
  css,
  style,
  // system functions
  borders,
  display,
  flexbox,
  palette,
  positions,
  shadows,
  sizing,
  spacing,
  typography,
  // composed
  all,
  sizingSpacing,
  // theme
  theme,
}
