import React, { useReducer, useContext, useEffect } from 'react'
import { navigate } from 'gatsby'

import { Heading, Grid, Box, Input, Button, Card } from '../system/components'
import { FirebaseContext } from './Firebase'
import { getAllTags, getAllVendors } from './Firebase/getters'
import { setIncomeExpense, deleteIncomeExpense } from './Firebase/setters'
import { TagsGroup } from './TagsGroup'
import { VendorInput } from './VendorInput'

const defaultInitialState = {
  vendor: '',
  description: '',
  amount: '',
  tags: [],
  vendors: [],
  submitPending: false,
  submitSuccessful: false,
  selectedTags: {},
  deleteConfirmation: false,
  deletePending: false,
  deleteSuccess: false,
  isFormDirty: false,
}

const formatAmount = amount => {
  return amount.replace(/-/g, '')
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_TAGS_SUCCESS':
      return { ...state, tags: action.payload }
    case 'GET_VENDORS_SUCCESS': {
      return { ...state, vendors: action.payload }
    }
    case 'UPDATE_SELECTED_TAGS': {
      const tmp = {
        ...state.selectedTags,
      }

      if (action.payload.label) {
        tmp[action.payload.index] = {
          label: action.payload.label,
          id: action.payload.id,
        }
      } else {
        delete tmp[action.payload.index]
      }

      return {
        ...state,
        isFormDirty: true,
        selectedTags: {
          ...tmp,
        },
      }
    }
    case 'UPDATE_VENDOR':
      return { ...state, isFormDirty: true, vendor: action.payload }
    case 'UPDATE_DESCRIPTION':
      return { ...state, isFormDirty: true, description: action.payload }
    case 'UPDATE_AMOUNT':
      return {
        ...state,
        isFormDirty: true,
        amount: formatAmount(action.payload),
      }
    case 'SUBMIT_PENDING':
      return { ...state, submitPending: true, submitSuccessful: false }
    case 'SUBMIT_SUCCESSFUL':
      return { ...state, submitPending: false, submitSuccessful: true }
    case 'ACTIVATE_DELETE_CONFIRMATION':
      return { ...state, deleteConfirmation: true }
    case 'CANCEL_DELETE_CONFIRMATION':
      return { ...state, deleteConfirmation: false }
    case 'DELETE_PENDING': {
      return { ...state, deletePending: true }
    }
    case 'RESET':
      return {
        ...defaultInitialState,
        tags: state.tags,
      }
    default:
      return state
  }
}

export const SetIncomeExpense = ({
  type,
  action,
  incomeExpenseId,
  initialState = defaultInitialState,
  onSubmit,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    vendor,
    vendors,
    description,
    amount,
    submitPending,
    submitSuccessful,
    tags,
    selectedTags,
    deleteConfirmation,
    deletePending,
    isFormDirty,
  } = state

  const firebase = useContext(FirebaseContext)
  const db = firebase.firestore()
  const areFieldsValid =
    vendor && (!!amount.match(/^\d+\.\d\d$/) || !!amount.match(/^\d+$/))

  useEffect(() => {
    getAllTags(list => {
      const tagList = list.filter(({ type: tagType }) => tagType === type)
      dispatch({ type: 'GET_TAGS_SUCCESS', payload: tagList })
    }, db)
    getAllVendors(list => {
      dispatch({ type: 'GET_VENDORS_SUCCESS', payload: list })
    }, db)
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    if (!areFieldsValid) return

    dispatch({ type: 'SUBMIT_PENDING' })
    setIncomeExpense(() => dispatch({ type: 'SUBMIT_SUCCESSFUL' }), firebase, {
      selectedTags: Object.keys(selectedTags).reduce((acc, key) => {
        const current = selectedTags[key]
        return {
          ...acc,
          [key]: {
            ...current,
            label: current.label.trim(),
          },
        }
      }, {}),
      tags,
      vendors,
      vendor: vendor.trim(),
      description,
      amount,
      type,
      incomeExpenseId,
      action,
    })
  }

  const handleDelete = () => {
    dispatch({ type: 'DELETE_PENDING' })
    setTimeout(() => {
      deleteIncomeExpense(db, incomeExpenseId)
    }, 500)
  }

  if (submitPending) {
    return (
      <Heading fontSize="h4.fontSize">
        Submitting {type === 'EXPENSE' ? 'expense' : 'income'}
      </Heading>
    )
  }

  if (submitSuccessful) {
    setTimeout(() => {
      if (onSubmit) onSubmit()
      if (action === 'Add') navigate('/app/home')
      if (action === 'Edit') navigate('/app/financial-overview')
    }, 600)

    return <Heading fontSize="h4.fontSize">Success!</Heading>
  }

  return (
    <>
      <Card>
        <Heading fontSize="h4.fontSize">
          {action} {action === 'Add' ? 'an ' : ' '}
          {type === 'EXPENSE' ? 'Expense' : 'Income'}
        </Heading>
        <Box
          as="form"
          width={{ xs: 1, sm: 2 / 3 }}
          onSubmit={e => handleSubmit(e)}
        >
          <Grid gap={2}>
            <Box width={1}>
              <VendorInput
                vendors={vendors}
                vendor={vendor}
                dispatch={dispatch}
              />
            </Box>
            <Box width={1}>
              <label>
                Description
                <Input
                  width={1}
                  type="text"
                  name="description"
                  value={description}
                  onChange={e =>
                    dispatch({
                      type: 'UPDATE_DESCRIPTION',
                      payload: e.target.value,
                    })
                  }
                />
              </label>
            </Box>
            <Box width={{ xs: 1, sm: 1 / 2 }}>
              <label>
                *Amount
                <Input
                  width={1}
                  type="number"
                  name="amount"
                  step="0.01"
                  min="0.01"
                  prepend="£"
                  placeholder="1.00"
                  value={amount}
                  onChange={e =>
                    dispatch({ type: 'UPDATE_AMOUNT', payload: e.target.value })
                  }
                />
              </label>
            </Box>
            <Box width={1}>
              <TagsGroup
                tags={tags}
                selectedTags={selectedTags}
                setSelectedTags={payload =>
                  dispatch({ type: 'UPDATE_SELECTED_TAGS', payload })
                }
              />
            </Box>
            <Box width={1} pt={2}>
              <Button
                disabled={!isFormDirty || !areFieldsValid}
                width={1}
                type="submit"
              >
                {action === 'Add' ? 'Submit ' : 'Edit '}{' '}
                {type === 'EXPENSE' ? 'expense' : 'income'}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Card>
      {action === 'Edit' ? (
        <Box pt={4}>
          {deleteConfirmation ? (
            <>
              <p>
                Are you sure you want to delete this{' '}
                {type === 'EXPENSE' ? 'expense' : 'income'}?
              </p>
              <Grid gap>
                <Box width={1 / 2}>
                  <Button
                    onClick={() =>
                      dispatch({ type: 'CANCEL_DELETE_CONFIRMATION' })
                    }
                    secondary
                    width={1}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box width={1 / 2}>
                  <Button width={1} onClick={handleDelete}>
                    {deletePending ? 'Deleting…' : 'Confirm'}
                  </Button>
                </Box>
              </Grid>
            </>
          ) : (
            <Box pb={4}>
              <Button
                onClick={() =>
                  dispatch({ type: 'ACTIVATE_DELETE_CONFIRMATION' })
                }
                secondary
                width={1}
              >
                Delete
              </Button>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  )
}
