import styled from 'styled-components'
import { all } from '..'

// eslint-disable-next-line
export const Card = styled.div`
  position: relative;
  padding: 16px;
  background-color: white;
  border: 2px solid #eee;

  &:: before {
    position: absolute;
    top: -2px;
    left: 0;
    bottom: -2px;
    width: 8px;
    content: ' ';
    ${({ income }) => income && `background-color: #2b7d1f;`}
    ${({ expense }) => expense && `background-color: #c31818;`}
  }
  ${all}
`
