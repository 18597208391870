import React from 'react'

import { Grid, Box } from '../system/components'
import { TagSelector } from './TagSelector'

export const TagsGroup = ({ tags, selectedTags, setSelectedTags }) => {
  const tagAlreadySelected = ({ id }) => {
    const ids = Object.keys(selectedTags).map(key => selectedTags[key].id)

    return !ids.includes(id)
  }

  const getSelectedTag = key => {
    const tag = selectedTags[key]
    return tag ? [tag] : []
  }

  const handleSetSelectedTag = (value, index) => {
    const matchingTagIndex =
      Object.keys(tags).find(
        key => tags[key].label.toLowerCase() === value.toLowerCase()
      ) || -1

    setSelectedTags({
      index,
      label: value,
      id: matchingTagIndex === -1 ? +new Date() : tags[matchingTagIndex].id,
    })
  }

  return (
    <Grid gap={2}>
      {Object.keys({ initial: '', ...selectedTags }).map((_, index) => {
        const currentSelectedTag = getSelectedTag(index)
        return (
          <Box key={index} width={{ xs: 1, sm: 1 / 2 }}>
            <TagSelector
              setSelectedTag={value => handleSetSelectedTag(value, index)}
              selectedTag={currentSelectedTag[0] && currentSelectedTag[0].label}
              tags={[
                ...currentSelectedTag,
                ...tags.filter(tag => tagAlreadySelected(tag)),
              ]}
            >
              Enter {index !== 0 ? 'another' : 'a'} tag
            </TagSelector>
          </Box>
        )
      })}
    </Grid>
  )
}
