import React from 'react'
import styled, { css } from 'styled-components'

import { all } from '..'

const renderVariant = props => {
  if (props.secondary) {
    return css`
      background-color: transparent;
      color: ${({ theme: { palette } }) => palette.secondary.main};
      border: 2px solid ${({ theme: { palette } }) => palette.secondary.main};

      &:hover,
      &:focus {
        color: white;
        background-color: ${({ theme: { palette } }) => palette.secondary.main};
        text-decoration: none;
      }
    `
  }

  return css`
    border: 2px solid ${({ theme: { palette } }) => palette.primary.main};
    background-color: ${({ theme: { palette } }) => palette.primary.main};

    &:hover,
    &:focus {
      background-color: ${({ theme: { palette } }) => palette.primary.dark};
      text-decoration: none;
    }
  `
}

const StyledButton = styled.button`
  display: block;
  text-align: center;
  text-decoration: none;
  border: none;
  padding: 0 30px;
  min-height: 40px;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  font: 800 11px system-ui;
  ${renderVariant}
  line-height: 40px;
  ${({ small }) =>
    small &&
    css`
      line-height: 30px;
      min-height: 30px;
      padding: 0 20px;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      background: grey;
      border: 2px solid grey;
      &:hover {
        background: grey;
      }
    `}
  ${all}
`

export const Button = ({
  full,
  secondary,
  children,
  onClick,
  disabled,
  small,
  ...rest
}) => (
  <StyledButton
    disabled={disabled}
    secondary={secondary}
    full={full}
    onClick={onClick}
    small={small}
    {...rest}
  >
    {children}
  </StyledButton>
)
