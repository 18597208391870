import React, { useReducer, useContext, useEffect } from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { FirebaseContext, getIncomeExpense, getAllTags } from './Firebase'
import { Card, Box, Grid, Heading, Button } from '../system/components'
import { COLORS, AUTHORS } from '../constants'
import { convertToCurrency } from '../utils'
import { SetIncomeExpense } from './SetIncomeExpense'

const StyledTag = styled.li`
  display: inline-block;
  padding: 2px 6px;
  margin-right: 5px;
  border-radius: 4px;
  background-color: #${({ hex }) => hex};
  color: #${({ color }) => color};
  font-size: 12px;
  font-weight: bold;
`

const defaultInitialEditState = {
  vendor: '',
  description: '',
  amount: '',
  tags: [],
  vendors: [],
  submitPending: false,
  submitSuccessful: false,
  selectedTags: {},
}

const defaultState = {
  incomeExpenseLoading: false,
  incomeExpense: {},
  tagsLoading: false,
  tags: [],
  isEditing: false,
}

const reducer = (state, action) => {
  const [type, payload] = action
  switch (type) {
    case 'GET_INCOME_EXPENSE_PENDING':
      return {
        ...state,
        incomeExpenseLoading: true,
      }
    case 'GET_INCOME_EXPENSE_SUCCESS':
      return {
        ...state,
        incomeExpenseLoading: false,
        incomeExpense: payload,
      }
    case 'GET_TAGS_PENDING':
      return {
        ...state,
        tagsLoading: true,
      }
    case 'GET_TAGS_SUCCESS':
      return {
        ...state,
        tagsLoading: false,
        tags: payload,
      }
    case 'SET_EDIT':
      return {
        ...state,
        isEditing: !state.isEditing,
      }
    default:
      return state
  }
}

export const SingleIncomeExpense = ({ id }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const {
    incomeExpenseLoading,
    incomeExpense,
    tagsLoading,
    tags,
    isEditing,
  } = state

  const firebase = useContext(FirebaseContext)
  const db = firebase.firestore()

  useEffect(() => {
    getIncomeExpense(
      payload => dispatch(['GET_INCOME_EXPENSE_SUCCESS', payload]),
      db,
      id
    )
    getAllTags(payload => dispatch(['GET_TAGS_SUCCESS', payload]), db)
  }, [])

  if (incomeExpenseLoading || tagsLoading) {
    return <div>Loading…</div>
  }

  if (!incomeExpense) {
    return <div>Delete successful</div>
  }

  if (!Object.keys(incomeExpense).length) return null

  const {
    timestamp,
    type,
    vendor,
    author,
    amount,
    description,
    tags: tagIds,
  } = incomeExpense
  return (
    <>
      <Heading fontSize="h4.fontSize">
        {`Financial Overview (Single ${
          type === 'INCOME' ? 'Income' : 'Expense'
        })`}
      </Heading>
      <Button mb={4} width={1 / 4} as={Link} to="/app/financial-overview">
        Back
      </Button>
      <Card
        key={timestamp}
        mb={2}
        income={type === 'INCOME'}
        expense={type === 'EXPENSE'}
      >
        <Grid>
          <Box width={1 / 2}>
            <Box>
              <strong>{vendor}</strong>
            </Box>
            {description ? (
              <Box>
                <p>{description}</p>
              </Box>
            ) : null}
            <Box>Entered by {AUTHORS[author]}</Box>
          </Box>
          <Box textAlign="right" width={1 / 2}>
            <Box>
              <strong>{convertToCurrency(amount)}</strong>
            </Box>
            <Box>{format(timestamp.toDate(), 'Do MMMM')}</Box>
          </Box>
        </Grid>
        <Box as="ul" pt={1}>
          {tagIds.map((tagId, index) => {
            const { label, hex } = tags.find(tag => tag.id === tagId) || {}
            return (
              <StyledTag hex={hex} key={index} color={COLORS[hex]}>
                {label}
              </StyledTag>
            )
          })}
        </Box>
      </Card>
      <Button my={4} width={1 / 4} onClick={() => dispatch(['SET_EDIT'])}>
        {isEditing ? 'Cancel' : 'Edit'}
      </Button>

      {isEditing ? (
        <SetIncomeExpense
          action="Edit"
          type={type}
          incomeExpenseId={id}
          initialState={{
            ...defaultInitialEditState,
            vendor,
            description,
            amount: convertToCurrency(amount).replace(/£|,/g, ''),
            selectedTags: tagIds.reduce((acc, tagId, index) => {
              const { label } = tags.find(t => t.id === tagId) || {}
              return {
                ...acc,
                [index]: { label, id: tagId },
              }
            }, {}),
          }}
        />
      ) : null}
    </>
  )
}
