import React from 'react'
import styled, { css } from 'styled-components'

import { sizingSpacing } from '..'

const StyledInputWrapper = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;

  ${({ prepend }) =>
    prepend &&
    css`
      &::before {
        position: absolute;
        top: 2px;
        left: 2px;
        bottom: 2px;
        width: 25px;
        text-align: center;
        line-height: 38px;
        background-color: white;
        border-right: 2px solid #ededed;
        cursor: text;
        content: '${prepend}';
      }
    `}
  ${sizingSpacing}
`

const StyledInput = styled.input`
  font-size: 1rem;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #ededed;
  height: 40px;
  ${({ prepend }) => prepend && `padding-left: 30px;`}
  ${sizingSpacing}
`

export const Input = ({ width, prepend, ...props }) => (
  <StyledInputWrapper width={width} prepend={prepend}>
    <StyledInput {...props} width={width} prepend={prepend} />
  </StyledInputWrapper>
)
