import React from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label`
  position: relative;
  display: block;
  padding: 6px;
  background-color: white;
  border: 2px solid #ededed;
  cursor: pointer;
`

const StyledIndicator = styled.div`
  position: absolute;
  top: 50%;
  left: 6px;
  width: 48px;
  height: 16px;
  border: 2px solid ${({ theme: { palette } }) => palette.primary.main};
  transform: translateY(-50%);
  border-radius: 6px;

  &::before {
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateX(0) translateY(-50%);
    width: 16px;
    height: 8px;
    border: 2px solid ${({ theme: { palette } }) => palette.primary.main};
    content: ' ';
    transition: transform 0.2s ease, background-color 0.2s ease;
    border-radius: 8px;
  }
`

const StyledInput = styled.input`
  opacity: 0;
  margin-right: 38px;

  &:checked + ${StyledIndicator}::before {
    transform: translateX(24px) translateY(-50%);
    background-color: ${({ theme: { palette } }) => palette.primary.main};
  }
`

export const Checkbox = ({ onChange, id, checked, label }) => (
  <StyledLabel>
    <StyledInput
      onChange={() => onChange(id)}
      type="checkbox"
      checked={checked}
      name={label}
    />
    <StyledIndicator />
    {label}
  </StyledLabel>
)
