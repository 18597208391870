import React, { useContext, useReducer } from 'react'
import styled from 'styled-components'
import Downshift from 'downshift'

import { FirebaseContext } from './Firebase'
import { Card, Box, Button, Input } from '../system/components'
import { CrossIcon, ArrowIcon } from '../system/icons'
import { COLORS } from '../constants'

const StyledClearButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  background-color: white;
  border: 2px solid #ededed;

  cursor: pointer;
`

const StyledTag = styled.div`
  display: inline-block;
  min-width: 30px;
  padding: 2px 6px;
  margin-right: 5px;
  border-radius: 4px;
  background-color: #${({ hex }) => hex};
`

const StyledLabel = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100px;
  ${({ isEditing, pushDown }) => isEditing && pushDown && 'margin-top: 10px;'}
`

const StyledInputWrapper = styled.div`
  position: relative;
`

const StyledField = styled.li`
  margin-bottom: 10px;
`

const StyledColorItem = styled.li`
  padding: 8px 0;
  cursor: pointer;
  color: transparent;
  background-color: #${({ color }) => color};
  text-align: center;
  font-weight: bold;
  color: ${({ textColor, highlighted }) =>
    highlighted ? `#${textColor}` : 'transparent'};
`

const StyledColorDropdown = styled.ul`
  max-height: 250px;
  overflow-y: ${({ isOpen }) => (isOpen ? 'scroll' : 'visible')};
`

export const TagToManage = ({ tag, isEditing, onEditClick }) => {
  const initialState = {
    label: tag.label,
    hex: tag.hex,
    submitPending: false,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'UPDATE_LABEL':
        return {
          ...state,
          label: action.payload,
        }
      case 'UPDATE_HEX':
        return {
          ...state,
          hex: action.payload,
        }
      case 'RESET':
        return {
          ...initialState,
        }
      case 'SUBMIT_PENDING':
        return {
          ...state,
          submitPending: true,
        }
      case 'SUBMIT_SUCCESS':
        return {
          ...state,
          submitPending: false,
        }
      default:
        return state
    }
  }

  const firebase = useContext(FirebaseContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { label, hex, submitPending } = state
  const isDirty = label !== tag.value || hex !== tag.hex

  const handleSubmit = () => {
    dispatch({ type: 'SUBMIT_PENDING' })
    const db = firebase.firestore()
    db.collection('tags')
      .doc(`${tag.id}`)
      .set({
        ...tag,
        label,
        hex,
      })
      .then(() => {
        dispatch({ type: 'SUBMIT_SUCCESS' })
        onEditClick('')
      })
  }

  return (
    <li>
      <Card mb={2}>
        <ul>
          <StyledField>
            <StyledLabel pushDown isEditing={isEditing}>
              Label:
            </StyledLabel>
            {isEditing ? (
              <Input
                type="text"
                name="label"
                value={label}
                onChange={e =>
                  dispatch({ type: 'UPDATE_LABEL', payload: e.target.value })
                }
              />
            ) : (
              label
            )}
          </StyledField>
          <StyledField>
            <StyledLabel pushDown isEditing={isEditing}>
              Colour:
            </StyledLabel>
            {isEditing ? (
              <Downshift
                onChange={selection =>
                  dispatch({ type: 'UPDATE_HEX', payload: selection || hex })
                }
                itemToString={item => item || ''}
              >
                {({
                  getInputProps,
                  getItemProps,
                  getToggleButtonProps,
                  getMenuProps,
                  isOpen,
                  highlightedIndex,
                  selectedItem,
                  openMenu,
                  clearSelection,
                }) => (
                  <div style={{ display: 'inline-block' }}>
                    <StyledInputWrapper>
                      <Input
                        width={1}
                        {...getInputProps({
                          disabled: true,
                          onFocus: openMenu,
                          style: {
                            color: 'transparent',
                            backgroundColor:
                              (selectedItem && `#${selectedItem}`) || `#${hex}`,
                          },
                        })}
                      />
                      {selectedItem ? (
                        <StyledClearButton onClick={clearSelection}>
                          <CrossIcon />
                        </StyledClearButton>
                      ) : (
                        <StyledClearButton {...getToggleButtonProps()}>
                          <ArrowIcon isOpen={isOpen} />
                        </StyledClearButton>
                      )}
                    </StyledInputWrapper>
                    <StyledColorDropdown isOpen={isOpen} {...getMenuProps()}>
                      {isOpen
                        ? Object.keys(COLORS).map((color, index) => (
                            <StyledColorItem
                              color={color}
                              textColor={COLORS[color]}
                              highlighted={highlightedIndex === index}
                              {...getItemProps({
                                key: color,
                                index,
                                item: color,
                              })}
                            >
                              Select
                            </StyledColorItem>
                          ))
                        : null}
                    </StyledColorDropdown>
                  </div>
                )}
              </Downshift>
            ) : (
              <StyledTag hex={hex}>&nbsp;</StyledTag>
            )}
          </StyledField>
          <StyledField>
            <StyledLabel isEditing={isEditing}>Type:</StyledLabel> {tag.type}
          </StyledField>
        </ul>
        {isEditing ? (
          <>
            {!submitPending && (
              <Box display="inline-block" mr={2}>
                <Button
                  secondary
                  onClick={() => {
                    dispatch({ type: 'RESET' })
                    onEditClick('')
                  }}
                >
                  Cancel
                </Button>
              </Box>
            )}
            <Box display="inline-block">
              <Button
                disabled={!isDirty || submitPending}
                onClick={handleSubmit}
              >
                {submitPending ? 'Submitting…' : 'Submit'}
              </Button>
            </Box>
          </>
        ) : (
          <Button onClick={() => onEditClick(tag.id)}>Edit</Button>
        )}
      </Card>
    </li>
  )
}
