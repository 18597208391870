import React, { useReducer, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Link } from 'gatsby'

import {
  FirebaseContext,
  getAllTags,
  getAllIncomeExpensesForGivenMonth,
  getFirstIncomeExpenseTimestamp,
} from './Firebase'
import { Heading, Card, Box, Grid } from '../system/components'
import { COLORS, AUTHORS } from '../constants'
import { convertToCurrency } from '../utils'
import { Totals } from './Totals'
import { MonthFilter, setInitialMonth } from './MonthFilter'
import { Filters } from './Filters'

const StyledTag = styled.li`
  display: inline-block;
  padding: 2px 6px;
  margin-right: 5px;
  border-radius: 4px;
  background-color: #${({ hex }) => hex};
  color: #${({ color }) => color};
  font-size: 12px;
  font-weight: bold;
`

const StyledLink = styled(Link)`
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const reducer = (state, action) => {
  const [type, payload] = action
  switch (type) {
    case 'GET_INCOME_EXPENSES':
      return {
        ...state,
        incomeExpensesLoading: true,
      }
    case 'GET_INCOME_EXPENSES_SUCCESS':
      return {
        ...state,
        incomeExpensesLoading: false,
        incomeExpenses: payload,
      }
    case 'GET_TAGS':
      return {
        ...state,
        tagsLoading: true,
      }
    case 'GET_TAGS_SUCCESS':
      return {
        ...state,
        tagsLoading: false,
        tags: payload,
      }
    case 'SET_SELECTED_MONTH':
      return {
        ...state,
        selectedMonth: payload,
      }
    case 'SET_START_OF_TIME':
      return {
        ...state,
        startOfTime: payload,
      }
    case 'FILTER_CHANGE':
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload]: !state.filters[payload],
        },
      }
    default:
      return state
  }
}

const initialState = {
  incomeExpensesLoading: true,
  tagsLoading: false,
  incomeExpenses: [],
  tags: [],
  selectedMonth: setInitialMonth(),
  startOfTime: null,
  filters: {},
}

export const Overview = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    incomeExpenses,
    tags,
    incomeExpensesLoading,
    tagsLoading,
    selectedMonth,
    startOfTime,
    filters,
  } = state

  const firebase = useContext(FirebaseContext)
  const db = firebase.firestore()

  useEffect(() => {
    async function fetchData() {
      dispatch(['GET_INCOME_EXPENSES'])
      const payload = await getFirstIncomeExpenseTimestamp(db)
      dispatch(['SET_START_OF_TIME', payload])
    }
    fetchData()
  }, [])

  useEffect(() => {
    async function fetchData() {
      dispatch(['GET_INCOME_EXPENSES'])
      if (!startOfTime) return
      const allIncomeExpensesForGivenMonth = await getAllIncomeExpensesForGivenMonth(
        db,
        new Date(selectedMonth)
      )
      dispatch(['GET_INCOME_EXPENSES_SUCCESS', allIncomeExpensesForGivenMonth])
    }
    fetchData()
  }, [startOfTime, selectedMonth])

  useEffect(() => {
    dispatch(['GET_TAGS'])
    getAllTags(payload => dispatch(['GET_TAGS_SUCCESS', payload]), db)
  }, [])

  const OverviewHeading = () => (
    <Heading fontSize="h4.fontSize" mb={4}>
      Financial overview
    </Heading>
  )

  const handleFilterChange = filterId => {
    dispatch(['FILTER_CHANGE', filterId])
  }

  if (incomeExpensesLoading || tagsLoading) {
    return (
      <>
        <OverviewHeading />
        <div>Loading…</div>
      </>
    )
  }

  if (!incomeExpenses.length) {
    return (
      <>
        <OverviewHeading />
        <Box pb={5}>
          {startOfTime && (
            <MonthFilter
              startOfTime={startOfTime}
              selectedMonth={selectedMonth}
              setSelectedMonth={payload =>
                dispatch(['SET_SELECTED_MONTH', payload])
              }
            />
          )}
        </Box>
        <div>There are no results for your chosen filter.</div>
      </>
    )
  }

  const filterIncomeExpenses = () => {
    if (!Object.values(filters).includes(true)) return incomeExpenses
    const selectedFilters = Object.keys(filters).filter(key => filters[key])
    return incomeExpenses.filter(
      ie => !!selectedFilters.some(sf => ie.tags.includes(Number(sf)))
    )
  }

  const filteredIncomeExpenses = filterIncomeExpenses()

  return (
    <div>
      <OverviewHeading />
      {startOfTime && (
        <Box pb={5}>
          <MonthFilter
            startOfTime={startOfTime}
            selectedMonth={selectedMonth}
            setSelectedMonth={payload =>
              dispatch(['SET_SELECTED_MONTH', payload])
            }
          />
        </Box>
      )}
      <Filters
        tags={tags}
        filters={filters}
        onFilterChange={handleFilterChange}
        incomeExpenses={incomeExpenses}
      />
      <Totals
        selectedMonth={selectedMonth}
        incomeExpenses={filteredIncomeExpenses}
      />
      <Heading fontSize="h5.fontSize">Line items</Heading>
      <Box as="ul" width={{ xs: 1, sm: 2 / 3, md: 1 / 2 }}>
        {filteredIncomeExpenses.map(
          ({ vendor, author, amount, timestamp, tags: tagIds, type, id }) => {
            return (
              <li key={id}>
                <StyledLink to={`/app/financial-overview/${id}`}>
                  <Card
                    key={timestamp}
                    mb={2}
                    income={type === 'INCOME'}
                    expense={type === 'EXPENSE'}
                  >
                    <Grid>
                      <Box width={1 / 2}>
                        <Box>
                          <strong>{vendor}</strong>
                        </Box>
                        <Box>Entered by {AUTHORS[author]}</Box>
                      </Box>
                      <Box textAlign="right" width={1 / 2}>
                        <Box>
                          <strong>{convertToCurrency(amount)}</strong>
                        </Box>
                        <Box>{format(timestamp.toDate(), 'Do MMMM')}</Box>
                      </Box>
                    </Grid>
                    <Box as="ul" pt={1}>
                      {tagIds.map((tagId, index) => {
                        const { label, hex } =
                          tags.find(tag => tag.id === tagId) || {}
                        return (
                          <StyledTag hex={hex} key={index} color={COLORS[hex]}>
                            {label}
                          </StyledTag>
                        )
                      })}
                    </Box>
                  </Card>
                </StyledLink>
              </li>
            )
          }
        )}
      </Box>
    </div>
  )
}
