import React from 'react'
import { Link } from 'gatsby'
import { getUser } from '../services/auth'

import {
  Box,
  Heading,
  Grid,
  Button,
  Card,
  Container,
  CenterVertically,
} from '../system/components'

export const Home = () => (
  <CenterVertically>
    <Container>
      <Card>
        <Heading fontSize="h4.fontSize">
          Welcome to Finances {getUser().name}
        </Heading>
        <p>
          Click an option below to add an income, an expense or take a financial
          overview.
        </p>
        <Grid gap>
          <Box width={{ xs: 1, sm: 1 / 2 }}>
            <Button as={Link} to="/app/add-income" width="1">
              Add an Income
            </Button>
          </Box>
          <Box width={{ xs: 1, sm: 1 / 2 }}>
            <Button as={Link} to="/app/add-expense" width="1">
              Add an Expense
            </Button>
          </Box>
          <Box width={{ xs: 1, sm: 1 / 2 }}>
            <Button as={Link} to="/app/manage-tags" width="1">
              Manage tags
            </Button>
          </Box>
          <Box width={{ xs: 1, sm: 1 / 2 }}>
            <Button as={Link} to="/app/financial-overview" width="1">
              Financial overview
            </Button>
          </Box>
        </Grid>
      </Card>
    </Container>
  </CenterVertically>
)
