import { useEffect } from 'react'

export const useEffectAsync = (effect, inputs) => {
  useEffect(() => {
    effect()
  }, inputs)
}

export const convertToCurrency = amount => {
  return (amount / 100).toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
  })
}
