import React from 'react'
import styled from 'styled-components'

import { sizingSpacing } from '..'

const StyledSelect = styled.select`
  font-size: 1rem;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid #ededed;
  height: 40px;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='DimGray'><polygon points='0,0 100,0 50,50'/></svg>")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 16px) 15px;
  background-repeat: no-repeat;
  background-color: white;
  -webkit-appearance: none;
  ${sizingSpacing}
`

export const Select = ({ options, label, ...props }) => (
  <label>
    {label}
    <StyledSelect {...props}>
      {options.map(option => (
        <option value={option} key={option}>
          {option}
        </option>
      ))}
    </StyledSelect>
  </label>
)
