import styled from 'styled-components'

export const StyledSuggesionItem = styled.li`
  padding: 8px 10px;
  cursor: pointer;
  ${({ highlighted }) => highlighted && `background-color: #ededed`};
  &:not(:last-child) {
    border-bottom: 2px solid #ededed;
  }
`

export const StyledSuggesionList = styled.ul`
  border: 2px solid ${({ isOpen }) => (isOpen ? '#ededed' : 'transparent')};
  border-top: 0;
  max-height: 230px;
  overflow-y: scroll;
`

export const StyledInputWrapper = styled.div`
  position: relative;
`

export const StyledClearButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  border: 0;
  background-color: transparent;
  border-left: 2px solid #ededed;
  cursor: pointer;
`
