import React from 'react'
import { ThemeProvider } from 'styled-components'

import { theme } from '../system'
import { GlobalStyle } from '../system/components'

export const Root = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        {children}
      </>
    </ThemeProvider>
  )
}
