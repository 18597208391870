export const COLORS = {
  '5e07ff': 'ffffff',
  '99f78a': '111111',
  a7dbef: '111111',
  f2502b: '111111',
  dfbef7: '111111',
  '4c74bf': 'ffffff',
  aa281d: 'ffffff',
  f7cfaf: '111111',
  e58209: '111111',
  aefce1: '111111',
  '6fb2ce': '111111',
  ede480: '111111',
  '31bc99': '111111',
  db1176: '111111',
}

export const AUTHORS = {
  0: 'Robert',
  1: 'Dina',
}
